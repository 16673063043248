$color-form-green: #229c2d;
$color-form-grey: #979797;
$color-form-grey-light: #828282;
$color-form-red: #dd3131;

.gform_wrapper {
  display: block;

  form {
    div.validation_error {
      color: $color-form-red;
      border: 0;
      position: relative;
      font-weight: 400;
      text-align: left;
      padding: 1rem 1rem 1rem 2rem;
      margin: 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        color: $color-form-red;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' data-prefix='far' data-icon='exclamation-circle' class='svg-inline--fa fa-exclamation-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#dd3131' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z'></path></svg>");
        background-size: 100% 100%;
        background-position: center center;
      }
    }
  }

  .gform_body {
    .gform_fields {
      .gfield {
        margin: 0 0 2rem;

        input {
          height: 50px;
          border: 1px solid $color-form-grey;

          &:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
            padding: 0 1rem;
          }
        }

        .gfield_label {
          font-size: 1rem;
          font-weight: normal;

          .gfield_required{
            color: #000;
          }
        }

        &.hide-label {
          .gfield_label {
            display: none;
          }
        }

        .ginput_container {
          margin-top: 0;
        }

        .ginput_container_number,
        .ginput_container_text {
          margin-top: 0;

          select,
          input {

            &:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
              padding: 0 1rem;
            }
          }
        }

        .ginput_container_textarea {
          textarea {
            border: 1px solid $color-form-grey;
            padding: 1rem;
          }
        }

        .ginput_container_select {
          select {
            border: 1px solid $color-form-grey;
            height: 50px;
            background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' data-prefix='far' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'></path></svg>");
            background-origin: content-box;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 12px 15px;
            padding: 0 1rem;

            &:hover {
              cursor: pointer;
            }

            option {
              &.gf_placeholder {
                color: $color-form-grey-light;
              }
            }
          }
        }

        .ginput_container_multiselect {
          select {
            border: 1px solid #979797;
            padding: 1rem;

            option {
              margin-bottom: .3rem;
            }
          }
        }

        .ginput_container_checkbox {
          .gfield_checkbox {
            li {

              label {
                display: block;
                position: relative;
                padding: 2px 0 2px 2.5rem;
                font-size: 1rem;

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 2px;
                  display: block;
                  background-color: #fff;
                  border: 1px solid #979797;
                  width: 25px;
                  height: 25px;
                }
              }

              input[type=checkbox] {
                position: absolute;
                left: -999em;

                &:focus + label:before {
                  box-shadow: 0px 3px 14px 0px rgba(0,0,0,0.3);
                  border: 1px solid #979797;
                  font-weight: 500;
                }

                &:checked + label:before {
                  background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'></path></svg>");
                  background-size: 75% 75%;
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              }
            }
          }
        }

        .ginput_container_radio {
          .gfield_radio {
            li {

              label {
                display: block;
                position: relative;
                padding: 2px 0 2px 2.5rem;
                font-size: 1rem;

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 2px;
                  display: block;
                  background-color: #fff;
                  border: 1px solid #979797;
                  width: 25px;
                  height: 25px;
                  border-radius: 50%
                }

                &:after {
                  content: '';
                  position: absolute;
                  left: 4px;
                  top: 6px;
                  display: block;
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                  background-color: $color-form-green;
                  opacity: 0;
                }
              }


              input[type=radio] {
                position: absolute;
                left: -999em;

                &:focus + label:before {
                  box-shadow: 0px 3px 14px 0px rgba(0,0,0,0.3);
                  border: 1px solid #979797;
                  font-weight: 500;
                }

                &:checked + label:after {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.radio_small {
          .ginput_container_radio {
            .gfield_radio {
              text-align: left;

              li {
                display: inline-block;
                clear: none;

                &:not(:last-child){
                  margin-right: 2rem;
                }

                label {
                  white-space: nowrap;
                }
              }
            }
          }
        }

        .ginput_container_date {
          position: relative;

          input {
            width: 100%;

            &:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
              padding: 0 1rem;
            }
          }

          .ui-datepicker-trigger {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;

            &:hover {
              cursor: pointer;
            }
          }

          .clear-multi {
            .ginput_container_date {
              width: 33.33%;
              margin: 0;

              &:not(:last-child){
                padding-right: 1rem;
              }

              select {
                border: 1px solid $color-form-grey;
                height: 50px;
                background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'></path></svg>");
                background-origin: content-box;
                background-position: right center;
                background-repeat: no-repeat;
                background-size: 12px 15px;
                padding: 0 1rem;

                &:hover {
                  cursor: pointer;
                }

                option {
                  &.gf_placeholder {
                    color: $color-form-grey-light;
                  }
                }
              }
            }
          }
        }

        .ginput_container_time {
          width: 50%;
          max-width: 50%;
          margin: 0;

          &:first-child {
            padding-right: 1rem;
          }

          input {
            width: 100%;

            &:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
              padding: 0 1rem;
            }
          }

          label {
            text-align: left;
            margin: 0;
          }

          i {
            display: none;
          }
        }

        .ginput_container_phone {
          input {
            &:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
              padding: 0 1rem;
            }
          }
        }

        .ginput_container_address {
          select {
            border: 1px solid $color-form-grey;
            height: 50px;
            background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'></path></svg>");
            background-origin: content-box;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 12px 15px;
            padding: 0 1rem;

            &:hover {
              cursor: pointer;
            }

            option {
              &.gf_placeholder {
                color: $color-form-grey-light;
              }
            }
          }

          label {
            font-size: 1rem;
            margin: 1rem 0 0 0;
          }
        }

        .ginput_container_fileupload {
          input {
            background-color: #fff;
            color: #000;
            padding:  0.75rem 1rem;
            height: auto;
            min-height: 50px;
          }

          .gform_drop_area {
            border: 1px solid $color-form-grey;
            background-color: #fff;

            .gform_button_select_files {
              background-color: $color-form-green;
              color: #fff;
              border: 0;
              font-size: 1rem;
              height: 45px;
              padding: 6px 1.5rem!important;

              &:hover {
                background-color: darken($color-form-green, 5%);
                text-decoration: underline;
              }
            }
          }

          .gform_drop_instructions {
            color: #000;
            font-size: 1rem;
          }
        }

        [type='text']:focus,
        [type='password']:focus,
        [type='date']:focus,
        [type='datetime']:focus,
        [type='datetime-local']:focus,
        [type='month']:focus,
        [type='week']:focus,
        [type='email']:focus,
        [type='number']:focus,
        [type='search']:focus,
        [type='tel']:focus,
        [type='time']:focus,
        [type='url']:focus,
        [type='color']:focus,
        textarea:focus {
          box-shadow: 0px 3px 14px 0px rgba(0,0,0,0.3);
          border: 1px solid #979797;
        }

        &.gf_left_half,
        &.gfield.gf_right_half {
          width: 50%;
        }

        input,
        textarea {
          margin-bottom: 0;

          &::placeholder {
            color: #828282;
          }
          &::-webkit-input-placeholder {
            color: #828282;
          }
          &::-moz-placeholder {
            color: #828282;
          }
          &:-ms-input-placeholder {
            color: #828282;
          }
          &:-moz-placeholder {
            color: #828282;
          }
        }

        div.ginput_complex span.ginput_left select,
        div.ginput_complex span.ginput_right select,
        input[type=radio],
        select {
          margin-bottom: 0;
        }

        &.gfield_error {
          margin: 0 0 2rem;
          border: 0;
          background-color: transparent;

          .validation_message {
            color: $color-form-red;
            border: 0;
            position: relative;
            font-weight: 400;
            text-align: left;
            padding: 0 0 0 1.5rem;
            margin: .5rem 0 0 0;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              display: block;
              width: 16px;
              height: 16px;
              background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='exclamation-circle' class='svg-inline--fa fa-exclamation-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z'></path></svg>");
              background-size: 100% 100%;
              background-position: center center;
            }
          }

          .gfield_label {
            color: #000;
            margin-top: 0;
          }

          &.gf_left_half,
          &.gf_right_half {
            margin: inherit;
            max-width: 50% !important;
            padding-right: 1rem !important;
            margin: 0 0 2rem;
          }
          .ginput_container {
            input,
            textarea,
            select {
              border-color: $color-form-red;
            }

            &.ginput_container_fileupload {
              .validation_message {
                display: none;
              }
            }
          }

          .ginput_container_checkbox .gfield_checkbox li label:before {
            border-color: $color-form-red;
          }

          .ginput_container_radio .gfield_radio li label:before {
            border-color: $color-form-red;
          }

          input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
          textarea {
            border-color: $color-form-red
          }
        }
      }
    }
  }

  .gform_footer input.button,
  .gform_footer input[type=submit],
  .gform_page_footer input.button,
  .gform_page_footer input[type=submit] {
    background-color: $color-form-green;
    color: #fff;
    border: 0;
    font-size: 1rem;
    height: 45px;
    padding: 6px 1.5rem!important;

    &:hover {
      background-color: darken($color-form-green, 5%);
      text-decoration: underline;
    }
  }
}

body {
  .gform_wrapper .top_label li.gfield.gf_left_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker),
  .gform_wrapper .top_label li.gfield.gf_right_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker) {
    margin-top: 0;
  }
}

//Tablet and smaller
@media only screen and (max-width: 63.938em) {
  .gform_wrapper ul.gform_fields li.gfield.gfield_error+li.gfield.gfield_error {
    margin-top: 0 !important;
  }
}

// Mobile
@media only screen and (max-width: 40em) {
  .gform_wrapper {
    .gform_body {
      .gform_fields {
        .gfield {
          &.gf_left_half,
          &.gf_right_half {
            display: inline-block;
            clear: none;
          }

          &.gf_left_half {
            padding-right: 16px;
          }

          .ginput_container_date {
            .clear-multi {
              display: block;

              .ginput_container_date {

                &:nth-child(1){
                  width: 50%;
                  display: inline-block;
                  clear: none;
                }
                &:nth-child(2){
                  width: 50%;
                  display: inline-block;
                  clear: none;
                  padding-right: 0 !important;
                }
                &:nth-child(3){
                  width: 100%;
                  padding: 0;
                  display: block;
                  margin-top: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}