// COLORS
$color-grey: #666666;
$color-grey-light: #f6f6f6;
$color-black: #000000;
$color-blue: #83a9d1;
$color-blue-light: #e6eef6;
$color-green: #9eba48;

$color-blossom: #CCE5CE;
$color-cherry: #A8D3AF;
$color-harvest: #90BB95;
$color-water-creation: #CAE8F3;
$color-culture: #96D3DD;
$color-history: #66C1BF;
$color-bodytext: #282727;
$color-junction: #2E675B;

@font-face {
	src: url('../fonts/sofia-pro-bold.ttf');
	font-family: "Sofia pro";
}

@font-face {
	src: url('../fonts/quicksand-medium.ttf');
	font-family: "Quicksand";
}

@font-face {
	src: url('../fonts/lato-regular.ttf');
	font-family: "Lato regular";
}

@font-face {
	src: url('../fonts/lato-black.ttf');
	font-family: "Lato black";
}

@font-face {
	src: url('../fonts/lato-light.ttf');
	font-family: "Lato light";
}

@font-face {
	src: url('../fonts/buttercookies.ttf');
	font-family: "Butter cookies";
}

// FONTS
$header: 'Sofia pro', serif;
$header-alt: 'Quicksand', cursive;
$bodytext: 'lato-regular', sans-serif;
$icons: "Font Awesome\ 5 Pro";
$transition: all .2s cubic-bezier(.17,.67,.83,.67);
