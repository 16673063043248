footer {
	background-color: $color-grey;
	display: block;
	padding: 0 0 3em 0;
	position: relative;
	z-index: 99;

	.inner-footer {
		padding-top: 3em;
		position: relative;

		.scrolltop {
			background-color: #fff;
			border-radius: 50%;
			bottom: 6em;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
			color: $color-grey;
			display: block;
			font-size: 1.7em;
			height: 60px;
			opacity: 0;
			position: fixed;
			right: 1.3em;
			text-align: center;
			transition: $transition;
			width: 60px;

			&.show {
				opacity: 1;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	h6 {
		color: #fff !important;
		margin-bottom: 1.5em;
	}

	p {
		color: #bcbbbb;
		font-size: .875em;

		a {
			color: #bcbbbb;
			text-decoration: underline;

			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.textwidget {
		overflow: hidden;
		ul {
			list-style: none;
			margin: 0;

			li {

				a {
					color: #f0f0f0;
					font-size: em-calc(14px);
					line-height: 2em;
					padding: 0 1em 0 1em;
					position: relative;
					text-decoration: underline;
					transition: $transition;

					&:before {
						color: #f0f0f0;
						content: '\f105';
						font-family: $icons;
						font-weight: 900;
						left: 0;
						position: absolute;
						top: -3px;
					}

					&:hover {
						padding: 0 .5em 0 1.5em;
						text-decoration: none;
					}
				}
			}
		}
	}

	.widget-row {
		display: block;

		.footer-logo {
			max-width: 100px;
			margin-top: 20px;
		}

		&:not(:last-child){
			margin-bottom: 3em;
		}
	}

	.footer-socials {
		.social-link {
			background-color: #fff;
			border-radius: 50%;
			color: $color-history;
			display: inline-block;
			height: 28px;
			margin-right: .5em;
			padding-top: 2px;
			position: relative;
			text-align: center;
			vertical-align: middle;
			width: 28px;

			&:hover {
				color: $color-grey;
			}
		}
	}

	.copyright {
		border-top: 1px solid #bcbbbb;
		display: block;
		margin-top: 2em;
		padding-top: 2em;
		width: 100%;

		.menu {
			.menu-item {
				a {
					color: #bcbbbb;
					padding: 0;
					position: relative;

					&:hover {
						color: #fff;
						text-decoration: underline;
					}
				}

				&:not(:last-child) {
					a {
						margin: 0 1em 0 0;
						padding: 0 1em 0 0;

						&:after {
							background-color: #bcbbbb;
							content: '';
							display: block;
							height: 100%;
							position: absolute;
							right: 0;
							top: 0;
							width: 1px;
						}
					}
				}
			}
		}
	}

	.gform_wrapper {
		.gform_body .gform_fields .gfield {
			margin-bottom: 1em;

			.gfield_label {
				color: #bcbbbb;
				font-size: .875em;

				.gfield_required {
					color: #bcbbbb;
					font-size: .875em;
				}
			}
		}

		.gform_footer {
			margin: 0;
			padding: 0;
		}

		.gform_footer input.button, .gform_wrapper .gform_footer input[type=submit],
		.gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
			background-color: $color-history;

			&:hover {
				background-color: $color-cherry;
			}
		}

		form div.validation_error,
		.gform_body .gform_fields .gfield.gfield_error .validation_message {
			color: #fff;
		}
	}
}
