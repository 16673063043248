//SMALL SCREENS ONLY
@media only screen and (min-width: 1024px) and (max-width: 1280px){
	header {

		.inner {

			#menu {

				.menu:not(.submenu) {
					display: inline-block;
				}

				.menu-item {
					display: block;
					text-align: left;
					width: 100%;

					a {
						color: $color-grey;
						display: inline-block;
						vertical-align: middle;

						&:before {
							background-color: $color-grey;
						}
					}

					&.menu-item-has-children {
						a {

							&:after {
								border-color: $color-grey transparent transparent;
							}
						}

						.submenu {
							box-shadow: none;
							left: auto;
							padding-bottom: .5rem;
							padding-left: 1.5rem;
							position: relative;
							top: auto;
						}
					}
				}

				&.open-menu {
					left: 0;
				}
			}
		}

		&.header-no-image,
		&.white-background {
			.mobile-menu-toggle {

				.line {
					background-color: $color-grey;
				}
			}

			.inner {
				margin-top: .6rem;
			}
		}
	}
}

//SMALL SCREENS
@media only screen and (max-width: 1280px){
	header {

		.mobile-menu-toggle {
			display: inline-block;
			margin-left: 1rem;
			margin-top: 2px;
			transition: $transition;
			vertical-align: middle;

			.line {
				background-color: #fff;
				display: block;
				height: 3px;
				margin: 5px auto;
				transition: $transition;
				width: 30px;
			}

			&.is-active {
				transform: rotate(45deg);
				transition-delay: 0.6s;

				.line {
					&:nth-child(1){
						transform: translateY(8px);
						transition-delay: 0.3s;
					}
					&:nth-child(2){
						width: 0px;
					}
					&:nth-child(3){
						transform: translateY(-8px) rotate(90deg);
						transition-delay: 0.3s;
					}
				}
			}
		}

		.inner {
			transition: $transition;

			#menu {
				background-color: #fff;
				border-bottom: 2px solid $color-blue;
				border-top: 2px solid $color-blue;
				left: -100%;
				padding: 2rem 0;
				position: absolute;
				text-align: center;
				top: 100%;
				transition: all .5s ease-in-out;
				width: 100%;

				.menu-item {

					a {
						color: $color-grey;
						display: inline-block;

						&:before {
							background-color: $color-grey;
						}
					}

					&.menu-item-has-children {


						.submenu {
							.menu-item {
								display: block;

								a {
									font-size: 1.125rem;
								}
							}
						}
					}
				}

				&.open-menu {
					left: 0;
				}
			}
		}

		&.header-no-image,
		&.white-background {
			.mobile-menu-toggle {

				.line {
					background-color: $color-grey;
				}
			}

			.inner {
				margin-top: .6rem;
			}
		}
	}

	.section {
		&.header-home {
			height: 600px;

			.inner {
				.search-row {
					margin-top: 3rem;
				}

				.button-row {
					.button-icon {
						background-size: 40px 40px;
						padding-left: 3rem;
					}
				}
			}

			.header-home-slider {

				.image {
					height: 600px;
				}
			}
		}

		&.grid-filter {
			.filter-row {
				.grid-filters {
					select {
						width: 200px;
					}
				}
			}
		}
	}
}

// TABLET
@media screen and (max-width: 63.9375em) {
	body.admin-bar header {
		top: 46px;
	}

	header {

		.inner {

			#menu {

				.menu-item {

					a {
						color: $color-grey;
						display: inline-block;

						&:before {
							background-color: $color-grey;
						}
					}

					&.menu-item-has-children {
						&> a {
							padding-right: 2rem;

							&:after {
								border: inset 6px;
								border-color: transparent transparent transparent $color-grey;
								border-left-style: solid;
								border-right-width: 0;
								content: '';
								display: block;
								height: 0;
								margin-top: 0;
								position: absolute;
								right: 1rem;
								top: 50%;
								width: 0;
							}
						}

						.submenu {
							.js-drilldown-back {
								a {
									&:before {
										background-color: transparent;
										border: inset 6px;
										border-color: transparent $color-grey transparent transparent;
										border-left-width: 0;
										border-left-width: 0;
										border-left-width: 0;
										border-right-style: solid;
										bottom: auto;
										content: '';
										display: inline-block;
										height: 0;
										left: auto;
										margin-right: .75rem;
										position: relative;
										right: auto;
										top: auto;
										transform: translateX(0);
										vertical-align: middle;
										width: 0;
									}
								}
							}
							.menu-item {
								display: block;

								a {
									font-size: 1.125rem;
								}
							}
						}
					}
				}
			}
		}
	}

	.section {
		&.header-home {
			height: 600px;

			.header-home-slider {

				.image {
					height: 600px;
				}
			}
		}

		&.header-featured {
			height: 600px;

			.featured-slider {

				.slide-item {
					height: 600px;
				}
			}

			.featured-slider-nav {
				padding: 0 2rem;
				width: 300px;
			}
		}

		&.grid-filter {
			.filter-row {
				margin-bottom: 5.5rem;

				.change-view {
					bottom: -4rem;
					top: auto;
				}
			}
		}

		&.arrangements {
			.item {
				.image-container {
					.price-container {
						right: .5rem;
						top: .5rem;
					}
				}
			}
		}


		.slider-items {
			.slick-arrow {
				&.slick-next {
					transform: translate(0%,-50%);
				}

				&.slick-prev{
					transform: translate(0%,-50%);
				}
			}
		}

		&.anchorlink-block {
			.image-container {
				.image {
					padding: 0 0 100%;
				}
			}
		}
	}

	footer {

		h6 {
			margin-bottom: .5rem;
		}

		.widgets {
			padding-left: 2rem;
			padding-right: 2rem;

			.widget {
				margin-bottom: 3rem;
			}
		}
	}

	.copyright {
		.menu {
			justify-content: center;
		}
	}
}

// MOBILE
@media screen and (max-width: 39.9375em) {

	.gform-theme-datepicker:not(.gform-legacy-datepicker) {
		padding-left: 8px !important;
		padding-right: 8px !important;
		padding-bottom: 8px !important;

		td {
			padding: 1px !important;
		}

		.ui-datepicker-calendar {
			th span {
				width: 28px !important;
			}

			td {
				width: 28px !important;
				height: 28px !important;
			}

			a.ui-state-default,
			span.ui-state-default {
				width: 28px !important;
				height: 28px !important;
				font-size: 12px !important;
				line-height: 28px !important;
			}
		}
	}

	header {
		padding: .65rem 1rem;

		.logo {
			height: 55px;
			width: 160px;

			.whitelogo {
				max-width: 60px;
			}
		}

		&.header-no-image,
		&.white-background {
			padding: .65rem 1rem;

			.logo {
				height: 55px;
				width: 160px;
			}

			.inner {
				margin-top: 0;
			}
		}
	}

	body {
		h1 {
			font-size: rem-calc(30px);
			line-height: rem-calc(40px);
		}

		h2 {
			font-size: rem-calc(26px);
			line-height: rem-calc(34px);

			&.heading-alt {
				font-size: rem-calc(30px);
				line-height: rem-calc(40px);
			}
		}

		h3 {
			font-size: rem-calc(20px);
			line-height: rem-calc(28px);
		}

		h6,
		h4 {
			font-size: rem-calc(18px);
			line-height: rem-calc(24px);
		}
	}

	.pagination li {
		display: inline-block;
	}

	.single-template .fixed-sidebar {
		padding-right: 0;
	}

	.section {
		margin: 3rem 0;

		&.header-home {
			.inner {
				.button-row {
					padding: 3rem 0 5rem;
					text-align: left;
				}
			}
		}

		&.header {
			&.image {
				height: 400px;
			}
		}

		&.header-featured {
			height: 390px;

			.featured-slider {
				.slide-item {
					height: 350px;
				}

				.slick-dots {
					display: block;
					margin: .5rem 0 0 0;
					text-align: center;
					width: 100%;

					li {
						display: inline-block;
						margin: .2rem;
						vertical-align: middle;

						button {
							background-color: #fff;
							border: 1px solid #979797;
							border-radius: 50%;
							color: $color-grey;
							display: inline-block;
							font-size: rem-calc(13px);
							height: 25px;
							line-height: rem-calc(21px);
							padding: 0;
							text-align: center;
							text-decoration: none;
							transition: $transition;
							vertical-align: middle;
							width: 25px;

							&:hover {
								background-color: $color-blue;
								border-color: $color-blue;
								color: #fff;
								padding: 0;
							}
						}

						&.slick-active {
							button {
								background-color: $color-blue;
								border-color: $color-blue;
								color: #fff;
								padding: 0;
							}
						}
					}
				}
			}

			.featured-slider-nav {
				display: none;
			}
		}

		&.columnsblock {
			margin-bottom: 0;

			.text {
				margin-bottom: 3rem;
			}

			.image {
				margin-bottom: .5rem;

				&:before {
					padding-top: 50%;
				}
			}
		}

		&.imageblock {
			padding: 3rem 0;

			&.threequarters {
				.image {
					width: 100%;
				}
			}

			.color-block {
				padding: 1.5rem;
			}
		}

		&.grid-filter {
			.filter-row {
				.search-section {
					width: 100%;

					.search {
						margin-right: 0;
					}

					.filter-search {
						width: 100%;
					}
				}

				.grid-filters {
					margin-top: .5rem;
					width: 100%;

					select {
						width: 100%;
					}
				}
			}

			.grid-item {
				height: 150px;
			}

			.posttype {
				margin: 0;
				padding: 25px 10px;

				span {
					position: relative;
					bottom: 5px;
				}

				.more-info {
					position: absolute;
					top: 30px;
				}
			}
		}

		&.five-items-layout,
		&.four-items-layout {
			.image-wrap {
				.image{
					&.size-large {
						height: 300px;
					}

					&.size-small {
						height: 200px;
					}
				}
			}
		}

		&.text-background {
			padding: 3rem 0;

			.image {
				height: 40%;
				width: 80%;
			}
		}

		&.logo-row {
			.logo-slider {
				.slick-arrow{
					&.slick-prev,
					&.slick-next {
						transform: translate(0, -50%);
					}
				}
			}
		}

		&.header-home {
			height: 550px;

			.header-home-slider {

				.image {
					height: 550px;
				}
			}

			.inner {
				padding-top: 50px;

				.search-row {
					.links {
						display: block;
						text-align: center;

						a {
							display: inline-block;
							font-size: 1rem;
							margin: .5rem;
						}
					}
				}

				.button-row {
					.button-icon {
						height: 42px;
						line-height: 20px;
						margin-top: 1rem;
					}
				}
			}
		}

		&.favorites-tabs {
			.tabs {
				display: block;
				padding-left: 1.5rem;

				.tabs-title {
					display: block;
					width: 100%;

					a {
						padding: .2rem 0 .2rem 2rem;
					}
				}
			}
		}

		&.anchorlink-block {
			.image-container {
				.image {
					padding: 0 0 55%;
				}
			}

			.inner {
				padding: 2rem 0 0;
			}
		}
	}

	.text {
		blockquote {
			p {
				font-size: 1.2rem;
				line-height: rem-calc(26px);
			}
		}

		.gform_wrapper {
			.gform_heading {
				padding: 0 1rem 1rem 1rem;

				.gform_title {
					font-size: rem-calc(20px);
					line-height: rem-calc(28px);
				}
			}

			.gform_body {
				padding: 1.5rem;
			}

			.gf_page_steps {
				padding: 0;
			}
		}
	}

	footer {
		.inner-footer {
			.scrolltop {
				bottom: 6rem;
				font-size: 1.3rem;
				height: 40px;
				right: 1.2rem;
				width: 40px;
			}
		}

		.menu {
			li {
				margin-bottom: .5rem;
				width: 50%;
			}
		}

		.copyright {
			.menu {
				li {
					text-align: center;
					width: 100%;
				}

				a {
					line-height: 1.5rem;
					margin: 0!important;
					padding: 0!important;
					white-space: normal;
				}

				::after{
					visibility: hidden;
				}
			}
		}
	}

	.pagination {
		margin-top: 1rem;

		li {
			a,
			span {
				font-size: rem-calc(13px);
				height: 25px;
				line-height: rem-calc(21px);
				text-align: center;
				width: 25px;
			}
		}
	}

	.section.title-row-icons .text.has-icon {
		padding: 0 5rem 4rem 5rem;

		// .section.title-row-icons .text.has-icon h2 {
		//
		// }

		.section.title-row-icons .text.has-icon .icon {
			bottom: 2px;
			height: 50px;
			top: auto;
			width: 50px;
		}
	}

	.smaller {
		font-size: 1.5rem;
	}
}
