@use "sass:math";

@function rem-calc($values, $base: null) {
	$rem-values: ();
	$count: length($values);

	// If no base is defined, defer to the global font size
	@if $base == null {
		$base: 16;
	}

	// If the base font size is a %, then multiply it by 16px
	// This is because 100% font size = 16px in most all browsers
	@if unit($base) == '%' {
		$base: ($base / 100%) * 16px;
	}

	// Using rem as base allows correct scaling
	@if unit($base) == 'rem' {
		$base: strip-unit($base) * 16px;
	}

	@if $count == 1 {
		@return -zf-to-rem($values, $base);
	}

	@for $i from 1 through $count {
		$rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
	}

	@return $rem-values;
}

@function strip-unit($num) {
	@return math.div($num, $num * 0 + 1);
}


@function -zf-to-rem($value, $base: null) {
	// Check if the value is a number
	@if type-of($value) != 'number' {
		@warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
		@return $value;
	}

	// Transform em into rem if someone hands over 'em's
	@if unit($value) == 'em' {
		$value: strip-unit($value) * 1rem;
	}

	// Calculate rem if units for $value is not rem or em
	@if unit($value) != 'rem' {
		$value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
	}

	// Turn 0rem into 0
	@if $value == 0rem {
		$value: 0;
	}

	@return $value;
}

@mixin tablet {
	@media screen and (min-width: 40rem) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: 1441px) {
		@content;
	}
}
