@media print {

  body {

    width: 100%;
    font-size: 12px !important;
    padding: 0 0 3rem;

    .meta-info h2,
    .meta-info .section,
    .slider-items .slick-arrow,
    .photo-gallery,
    .breadcrumbs,
    .call-to-action,
    header,
    footer {
      display: none !important;
    }

    .sticky {
      max-width: 100%;
    }

    .sticky.is-stuck {
      position: relative!important;
    }

    .large-6,
    .large-5 {
      max-width: 100%!important;
      width: 100%!important;
      display: block;
      flex: initial;
    }

    .single-template .fixed-sidebar {
      height: auto!important;
      padding-right: 0;
      width: 100%!important;
    }

    .map-single-marker,
    .acf-map-single,
    .sticky {
      width: 100%!important;
    }

    .single-template .text .usps:not(.gform_fields) {
      list-style: disc!important;
      list-style-type: disc!important;
    }

    .map-single-marker {
      display: block!important;
      margin: 0 0 3rem!important;
    }

    .single-template .info {
      padding: 1rem 0;
    }

    .acf-map-single {
      width: 100%;
    }

    .single-template .sidebar-section {
      height: 400px;
    }

    h1 {
      font-size: 1.2rem;
      line-height: 1;
      margin-bottom: 1rem;
    }

  }

  .single-template .body-text {
    height: auto!important;
  }


  // body {
  //   width: 100%;
  //   font-size: 12px !important;
  // }
  //
  // header,
  // footer {
  //   display: none !important;
  // }
  //
  // main {
  //   padding-top: 0;
  //
  //   .text ol li,
  //   .text p,
  //   .text a:not(.btn),
  //   .text ul li {
  //     font-size: 10px !important;
  //     line-height: 1;
  //   }
  //
  //   h1 {
  //     font-size: 1.2rem;
  //     line-height: 1;
  //     margin-bottom: 1rem;
  //   }
  //
  //   h2,
  //   h4,
  //   h3 {
  //     font-size: 1rem;
  //     line-height: 1;
  //     margin-bottom: 1rem;
  //   }
  //
  //   .single-template {
  //     padding: 0;
  //     margin: 0;
  //   }
  //
  //   .fixed-sidebar {
  //     position: relative !important;
  //     height: 500px !important;
  //     width: 100% !important;
  //
  //     .map-route {
  //       width: 100%;
  //       height: 500px;
  //     }
  //   }
  //
  //   .columns {
  //     width: 100% !important;
  //     max-width: 100% !important;
  //     flex: 0 0 100% !important;
  //     margin: 0 !important;
  //   }
  //
  //   .contact-info {
  //     margin: 0 !important;
  //   }
  //
  //   .info {
  //     padding: 0 !important;
  //   }
  //
  //   .gmnoprint,
  //   .breadcrumbs,
  //   .meta-info .section,
  //   .call-to-action,
  //   .fixed-sidebar .photo-gallery {
  //     display: none !important;
  //   }
  // }
}