header {
	display: flex;
	justify-content: space-between;
	left: 0;
	padding: .65em 2em;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;

	.mobile-menu-toggle {
		display: none;
	}

	.logo {
		align-items: center;
		display: inline-flex;
		height: 101px;
		justify-content: flex-start;
		transition: $transition;
		width: 236px;

		.whitelogo {
			display: block;
			max-width: 101px;
		}

		.darklogo {
			display: none;
		}
	}

	.inner {
		display: flex;
		align-items: center;
		vertical-align: middle;

		@include tablet {
			display: inline-block;
		}

		#menu {
			display: inline-block;
			vertical-align: middle;

			.menu-item {
				display: inline-block;
				vertical-align: middle;

				a {
					color: #fff;
					font-family: $bodytext;
					font-size: 1.1em;
					font-weight: 700;
					line-height: 2em;
					position: relative;

					&:before {
						background-color: #fff;
						bottom: .7em;
						content: '';
						height: 2px;
						left: 50%;
						position: absolute;
						transform: translateX(-50%);
						transition: $transition;
						width: 0;
					}
				}

				&:hover,
				&.current-menu-item {
					a {
						background-color: transparent;
						text-decoration: underline;
					}
				}

				&.menu-item-has-children {
					a {
						background-color: transparent;

						&:after {
							border-color: #fff transparent transparent;
							margin: 0;
							right: 6px;
							top: 50%;
							transform: translateY(-50%);
						}

						&:hover {
							background-color: transparent;
						}
					}

					.submenu {
						border: 0;
						box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.1);

						.menu-item {
							a {
								color: $color-grey;
								display: inline-block;
								font-size: .875em;
								margin-left: .7em;
								overflow: hidden;
								padding-left: 0;
								transition: $transition;
								vertical-align: middle;

								&:before {
									background-color: $color-grey;
									background-color: transparent;
									bottom: auto;
									content: '\f105';
									font-family: $icons;
									font-weight: 900;
									height: auto;
									left: -.5em;
									top: 50%;
									transform: translate(0,-50%);
									width: auto;
								}
							}

							&.current-menu-item {
								a {
									background-color: transparent;
								}
							}
						}
					}
				}
			}
		}

		.extra-menu {
			display: flex;
			align-items: center;

			@include tablet {
				display: inline-flex;
				vertical-align: middle;
			}

			a.sb-btn {
				position: relative;
				color: white !important;
				font-size: 1em;
				line-height: 1em;
				padding: .7em 1.25em;
				background-color: $color-history;
				border-radius: 6px;
				font-weight: 700;
				text-indent: -999em;
				transition: all .2s linear;
				border: 2px solid $color-history;

				&:before {
					content: '\f073';
					font-family: $icons;
					font-weight: 400;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					position: absolute;
					top: 0;
					left: 0;
					font-size: 1rem;
					text-indent: 0;
				}

				&:hover {
					background-color: $color-cherry;
					border-color: $color-cherry;
				}

				@include tablet {
					text-indent: 0;

					&:before {
						display: none;
					}
				}
			}

			.languages {
				margin: 2em 0 0;

				&:after {
					border: solid transparent;
					border-bottom-color: #fff;
					border-color: rgba(194, 225, 245, 0);
					border-width: 11px;
					bottom: 100%;
					content: " ";
					height: 0;
					margin-left: -11px;
					pointer-events: none;
					position: absolute;
					right: 5px;
					width: 0;
				}

				&:before {
					border: solid transparent;
					border-bottom-color: #acacac;
					border-color: rgba(136, 183, 213, 0);
					border-width: 12px;
					bottom: 100%;
					content: " ";
					height: 0;
					margin-left: -12px;
					pointer-events: none;
					position: absolute;
					right: 5px;
					width: 0;
				}

				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li {

						a {
							color: $color-blue;
						}
					}
				}
			}

			.language {
				padding: 1em 0 1em 0em;
			}

			button, a {
				color: #fff;
				display: inline-block;
				font-size: 1.2em;
				margin-left: .625em;
				transition: $transition;
				vertical-align: middle;

				&.has-favorites {
					color: red;
				}

				&:hover {
					// color: $color-green;
					cursor: pointer;
				}
			}
		}
	}

	&.header-no-image,
	&.white-background {
		background-color: #fff;
		box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);

		.logo {
			height: 54px;
			width: 157px;


			> .whitelogo {
				display: none;
			}

			> .darklogo {
				display: block;
				max-height: 65px;
			}
		}

		.inner {
			#menu {
				.menu-item {
					a {
						color: $color-grey;

						&:before {
							background-color: $color-grey;
						}
					}

					&.menu-item-has-children {
						a {
							&:after {
								border-color: $color-grey transparent transparent;
							}
						}
					}
				}
			}

			.extra-menu {
				button,a {
					color: $color-grey;

					&.has-favorites {
						color: red;
					}

					&:hover {
						color: $color-green;
					}
				}
			}
		}
	}
}

body.admin-bar {
	header {
		top: 32px;
	}
}
