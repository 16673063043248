// 1. BASIC
html {
	scroll-behavior: smooth;
}

body {
	font-family: $bodytext;

	.row {
		&:after,
		&:before {
			content: none;
		}
	}
}

a.skiplink {
	background-color: #000;
	color: #fff;
	display: block;
	font-size: 1.3em;
	left: -1000em;
	padding: 0.8em;
	position: absolute;
	z-index: 10;

	&:focus {
		left: 1em;
		top: 1em;
		z-index: 1000;
	}
}

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;

	&:focus {
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

// 2. ANIMATIONS
// 3. TYPOGRAPHY
.text {

	&::selection {
		background: $color-history;
		color: #fff;
	}

	&::-moz-selection {
		background: $color-history;
		color: #fff;
	}

	ul li,
	ol li,
	p {
		color: $color-grey;
		font-family: $bodytext;
		font-size: 1.125rem;
		line-height: 2rem;
		margin-bottom: 1.5rem;

		&:last-child {
			margin-bottom: 0;
		}

		&::selection {
			background: $color-blue;
			color: #fff;
		}

		&::-moz-selection {
			background: $color-blue;
			color: #fff;
		}

		a:not(.btn) {
			color: $color-grey;
			text-decoration: underline;
			transition: $transition;

			&:hover {
				color: $color-blue;
				text-decoration: none;
			}
		}

		&.small-text {
			font-size: rem-calc(13px);
			line-height: rem-calc(22px);
		}
	}

	blockquote {
		border: 0;
		margin: 1.5rem 0;
		padding: 0;
		position: relative;

		p {
			color: $color-blue;
			font-family: $header;
			font-size: rem-calc(28px);
			font-style: italic;
			line-height: rem-calc(38px);
		}
	}

	ul:not(.gform_fields) {
		list-style: none;
		margin: 1.5rem 0 0 0;

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}

		li {
			margin: 0;
			padding: 0 0 0 1.5rem;
			position: relative;

			&:before {
				background-color: $color-blue;
				border-radius: 50%;
				content: '';
				display: block;
				height: 8px;
				left: 0;
				position: absolute;
				top: 12px;
				width: 8px;
			}
		}
	}

	.gform_wrapper {
		.gform_heading {
			display: block;
			padding: 0 3rem 3rem 3rem;

			.gform_title {
				font-size: rem-calc(55px);
				line-height: rem-calc(60px);
				margin: 0;
				text-align: center;
			}
		}

		.gf_page_steps {
			border: 0;
			display: flex;
			justify-content: space-between;
			margin-bottom: 3rem;
			padding: 0 3rem;
			position: relative;
			width: 100%;

			&:after {
				background-color: $color-grey;
				content: '';
				display: block;
				height: 2px;
				left: 50%;
				position: absolute;
				top: 43px;
				transform: translateX(-50%);
				width: calc(90% - 6rem);
			}

			.gf_step_clear {
				display: none;
			}

			.gf_step {
				background-color: #fff;
				display: inline-block;
				height: auto;
				margin: 0;
				opacity: 1;
				padding: 87px 0 0 0;
				position: relative;
				vertical-align: middle;
				z-index: 2;

				.gf_step_number {
					background-color: #d8d8d8;
					border-radius: 50%;
					color: $color-grey;
					display: block;
					font-family: $header;
					font-size: rem-calc(34px);
					height: 87px;
					left: 50%;
					line-height: rem-calc(44px);
					margin: 0;
					padding-top: 17px;
					position: absolute;
					text-align: center;
					top: 0;
					transform: translateX(-50%);
					width: 87px;
				}

				.gf_step_label {
					color: $color-grey;
					display: block;
					font-family: $header;
					font-size: rem-calc(20px);
					line-height: rem-calc(28px);
					text-align: center;
				}

				&.gf_step_active {
					.gf_step_number {
						background-color: $color-blue;
						color: #fff;
					}
				}
			}
		}

		.gform_body {
			background-color: $color-grey-light;
			display: block;
			padding: 3rem;

			h3 {
				font-family: $header;
				font-size: rem-calc(28px);
				line-height: rem-calc(38px);
				margin: 0;
			}

			.gform_fields {
				.gfield {
					.gfield_label {
						color: $color-grey;
						font-size: rem-calc(13px);
						line-height: rem-calc(22px);
					}
				}
			}
		}

		.gform_footer {
			background-color: $color-grey-light;
			display: block;
			padding: 0 3rem 3rem 3rem;
		}

		.gform_footer,
		.gform_page_footer {
			border: 0;
			margin: 0;

			.button {
				background-color: transparent;
				border: 2px solid $color-blue;
				border-radius: 3rem;
				color: $color-blue;
				display: inline-block;
				font-family: $bodytext;
				font-size: 1.125rem;
				font-weight: 700;
				line-height: 2rem;
				overflow: hidden;
				padding: .4rem 2rem;
				position: relative;
				text-transform: uppercase;
				transition: $transition;
				vertical-align: middle;
				z-index: 2;

				&:hover {
					background-color: $color-blue;
					color: #fff;
				}
			}
		}
	}
}

body {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $color-bodytext;

		&::selection {
			background: $color-junction;
			color: #fff;
		}
		&::-moz-selection {
			background: $color-junction;
			color: #fff;
		}
	}
}

body {
	h1 {
		color: $color-blue;
		font-family: $header;
		font-size: rem-calc(55px);
		font-weight: 600;
		line-height: rem-calc(60px);
	}

	h2 {
		font-family: $header;
		font-size: rem-calc(45px);
		font-weight: 600;
		line-height: rem-calc(50px);

		&.heading-light {
			font-family: $header;
			font-weight: 300;
		}

		&.heading-alt {
			font-family: $header;
			font-size: rem-calc(60px);
			font-weight: 400;
			line-height: rem-calc(65px);
		}
	}

	h3 {
		font-family: $header;
		font-size: rem-calc(22px);
		font-weight: 600;
		line-height: rem-calc(38px);
	}

	h6,
	h4 {
		font-family: 'Lato black';
		font-size: rem-calc(20px);
		font-weight: 600;
		line-height: rem-calc(28px);
	}
}

/* SLICKSLIDER */
.slickslider {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;

	.slick-list,
	.slick-track {
		overflow: hidden;
		width: 100%;
	}

	li {
		float: left;

		img {
			width: 100%;
		}
	}
}

body .menu .active a {
	background: transparent;
}

/* GLOBAL STYLES */

#gtranslate_selector {
	display: none;
}

.has-tip {
	cursor: pointer;
}

.btn {
	background-color: transparent;
	border: 2px solid $color-history;
	border-radius: 3rem;
	color: $color-history;
	display: inline-block;
	font-family: $bodytext;
	font-size: 1rem;
	font-weight: 600;
	line-height: 2rem;
	overflow: hidden;
	padding: .2rem 1rem;
	position: relative;
	text-transform: uppercase;
	transition: $transition;
	vertical-align: middle;
	z-index: 2;

	&:not(:first-child) {
		margin-top: 2rem;
	}

	&:hover {
		background: $color-history;
		color: #fff;
	}

	&.white {
		border-color: #fff;
		color: #fff;

		&:before {
			background-color: #fff;
		}

		&:hover {
			background-color: #fff;
			color: $color-grey;
		}
	}
}

.image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;

	&.darken {
		position: relative;

		&:after {
			background-color: rgba(#000, .2);
			content: '';
			display: block;
			height: 100%;
			left: 0;
			pointer-events: none;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}

.reveal-overlay {
	background-color: rgba(0,0,0,0.6);

	.reveal {
		max-width: 80%;
		padding: 4rem;
		position: relative;
		width: 800px;

		.close-button {
			position: absolute;
			right: 1rem;
			top: 1rem;

			&:hover {
				color: #000;
				cursor: pointer;
			}
		}

		&.search-modal {
			background-color: $color-blue;
			border: 0;
			max-width: 80%;
			text-align: center;
			width: 700px;

			h2 {
				color: #fff;
				margin-bottom: 3rem;
			}

			.close-button {
				color: #fff;

				&:hover {
					color: #000;
				}
			}
		}
	}
}

.gform-theme-datepicker:not(.gform-legacy-datepicker) .ui-datepicker-header select {
	background-position: right -24px center !important;
}

.section.header-search {
	align-items: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: auto;
	justify-content: flex-end;
	margin-top: 0 !important;
	padding: 6.875rem 0 1.875rem;
	position: relative;

	&:after {
		background-color: rgba(0,0,0,.2);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.titlewrap {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: flex-start;
		padding-bottom: 2rem;

		h1 {
			color: white;
		}

		@include tablet {
			padding-bottom: 0;
		}
	}

	@include tablet {
		height: 700px;
		padding: 0;
	}

	> .row {
		width: 100%;
		z-index: 2;

		@include tablet {
			margin-top: 4.875rem;
		}

		@include desktop {
			margin-top: 0;
		}
	}

	.searchbox {
		background-color: white;
		border-radius: 10px;
		box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
		padding: 2.5rem 1.5rem;

		@include tablet {
			padding: 2.5rem 3rem;
		}
	}

	div.gform_wrapper {
		form.searchandbook {
			.gform_body {
				.gform_fields {
					position: relative;
					// display: block;

					.gfield {
						&.gsection {
							border: 1px solid #e6e9eb;
							border-radius: 6px;
							color: $color-black;
							cursor: pointer;
							display: block;
							height: 50px;
							line-height: 50px;
							margin: 0 0 3rem;
							padding: 0 1rem;
							position: relative;
							transition: all .2s linear;

							h3 {
								color: $color-black;
								font-family: $bodytext;
								font-size: 1rem;
								font-weight: 400;
								line-height: 48px;
								margin: 0;
								transition: all .2s linear;
							}

							.gsection_description {
								font-size: .8754rem;
								margin: 0;
								padding-top: 0;
							}

							&:after {
								color: $color-black;
								content: '\f107';
								font-family: $icons;
								font-size: 1.375rem;
								font-weight: 300;
								position: absolute;
								right: 1rem;
								top: 0;
								transition: all .2s linear;
							}

							&.active {
								border-color: $color-harvest;

								&:after {
									transform: rotate(-180deg);
								}
							}

							&:hover {
								border-color: $color-harvest;
							}
						}

						.ginput_container {
							input,
							select {
								border: 1px solid #e6e9eb;
								border-radius: 6px;
								box-shadow: none;
								transition: all .2s linear;

								&:focus {
									border-color: $color-harvest;
								}
							}

							&.ginput_container_number {
								align-items: center;
								display: flex;
								margin-left: auto;
								position: relative;

								input[type=text] {
									border: 0;
									box-shadow: none;
									font-weight: 700;
									max-width: 48px;
									padding: 0 !important;
									text-align: center;
									width: 48px;

									@include tablet {
										max-width: 38px;
										width: 38px;
									}

									&:focus {
										box-shadow: none;
									}
								}

								.increment,
								.decrement {
									align-items: center;
									border: 1px solid rgb(164 172 178);
									border-radius: 50px;
									cursor: pointer;
									display: inline-flex;
									font-size: 1.25rem;
									height: 38px;
									justify-content: center;
									position: relative;
									transition: all .2s linear;
									width: 38px;

									@include tablet {
										height: 48px;
										width: 48px;
									}

									&:after {
										border: 2px solid rgb(164 172 178);
										border-radius: 50px;
										content: '';
										height: 40px;
										left: -2px;
										opacity: 0;
										position: absolute;
										top: -2px;
										transition: all .2s linear;
										width: 40px;

										@include tablet {
											height: 50px;
											width: 50px;
										}
									}

									&:hover {
										&:after {
											opacity: 1;
										}
									}
								}

								.increment {
									background-color: $color-cherry;
									border-color: $color-cherry;
									color: white;

									&:after {
										border-color: $color-cherry;
									}
								}
							}
						}

						&.wrap {
							align-items: flex-start;
							display: flex;
							margin-bottom: 1rem;
							position: relative;

							.gfield_label {
								font-size: .875rem;
								margin-bottom: 0;
								padding-top: 3px;

								@include tablet {
									font-size: 1rem;
								}
							}

							.gfield_description {
								color: rgba(0,0,0,.5);
								font-size: .875rem;
								font-style: italic;
								padding-bottom: 0;
								position: absolute;
								top: 24px;
							}
						}

						&.animals {
							align-items: center;
							margin-bottom: 0;
						}
					}

					.persons {
						background-color: white;
						border: 1px solid $color-harvest;
						border-radius: 6px;
						height: 0;
						left: 0;
						opacity: 0;
						overflow: hidden;
						padding: 1.5rem 1rem;
						position: absolute;
						top: 56px;
						width: 100%;
						z-index: 2;

						&.is-open {
							height: auto;
							opacity: 1;
							overflow: visible;
						}

						@include tablet {
							padding: 1.5rem;
						}
					}
				}
			}

			.gform_footer {
				margin: 0;
				padding-bottom: 0;

				input[type=submit] {
					background-color: $color-history;
					border-radius: 6px;
					font-size: 1.125rem;
					font-weight: 700;
					margin-bottom: 0;
					transition: all .2s linear;
					width: 100%;

					&:hover {
						background-color: $color-harvest;
					}
				}
			}
		}
	}
}

.section {
	display: block;
	margin: 4rem 0;

	&.header-home {
		height: 700px;
		margin-top: 0;
		position: relative;
		width: 100%;

		.header-home-slider {
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			.search-row {
				pointer-events: none;

				.row {
					pointer-events: none;

					.columns {
						pointer-events: none;
					}
				}
			}

			.image {
				display: block;
				height: 700px;
				position: relative;
				width: 100%;

				&.darken {
					&::after {
						background-color: rgba(0,0,0,.2);
						content: '';
						display: block;
						height: 100%;
						left: 0;
						pointer-events: none;
						position: absolute;
						top: 0;
						width: 100%;
					}
				}


				// &:before,
				&:after {
					content: none;
					// position: absolute;
					// bottom: 0;
					// left: 0;
					// width: 100%;
					// height: 200px;
					// display: block;
					// background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
					// pointer-events: none;
					// z-index: 1;
				}
				//
				// &:after {
				//   background: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
				// }

				.picture-meta {
					background-color: rgba(#000, .7);
					bottom: 0;
					color: #fff;
					display: block;
					font-size: rem-calc(13px);
					line-height: rem-calc(22px);
					padding: 1rem;
					position: absolute;
					right: 0;
					z-index: 2;

					.author {
						i {
							margin-right: .3rem;
						}
					}
				}
			}
		}

		.inner {
			height: 100%;
			padding: 101px 0 0;
			pointer-events: none;
			position: relative;
			z-index: 2;

			.text {
				pointer-events: all;
			}

			.search-row {
				margin-top: 7rem;

				h1 {
					color: #fff;
					margin-bottom: 2rem;
					text-align: center;
				}

				.links {
					display: flex;
					justify-content: space-between;
					margin-top: .5rem;
					width: 100%;

					a {
						color: #fff;
						font-size: 1.125rem;
						line-height: 2rem;
						text-decoration: underline;

						&:hover {
							text-decoration: none;
						}
					}
				}
			}

			.button-row {
				bottom: 0;
				left: 0;
				padding: 3rem 0 4rem;
				pointer-events: all;
				position: absolute;
				text-align: center;
				width: 100%;

				.button-icon {
					background-position: left center;
					background-repeat: no-repeat;
					background-size: 64px 64px;
					color: #fff;
					display: inline-block;
					height: 64px;
					line-height: 64px;
					padding: 0 0 0 5rem;
					position: relative;
					vertical-align: middle;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.header-featured {
		display: block;
		height: 700px;
		margin-top: 0;
		position: relative;

		.featured-slider {
			display: block;
			height: 100%;

			.slide-item {
				align-items: center;
				display: flex;
				height: 700px;
				width: 100%;

				.content-wrap {
					display: block;
					position: relative;
					width: 100%;
					z-index: 1;

					.text {
						h1,h4 {
							color: #fff;
						}
					}
				}
			}
		}

		.featured-slider-nav {
			background-color: rgba(#000,.7);
			padding: 0 0 0 2rem;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 400px;

			.slide-item {
				border: 0;
				min-height: 122px;
				padding: 2rem 0;

				&:focus {
					outline: 0;
				}

				.subtitle {
					color: $color-junction;
					font-size: rem-calc(13px);
					font-weight: 700;
					line-height: rem-calc(22px);
					opacity: .4;
					text-transform: uppercase;
					transition: $transition;
				}

				p {
					color: #fff;
					font-size: 1.125rem;
					line-height: rem-calc(25px);
					line-height: 2rem;
					margin: 0;
					opacity: .4;
					transition: $transition;
				}

				&:not(:last-of-type) {
					border-bottom: 2px solid rgba(#fff, .2);
				}

				&:hover,
				&.slick-current {
					cursor: pointer;

					.subtitle {
						opacity: 1;
					}

					p {
						opacity: 1;
					}
				}
			}
		}
	}

	&.header {
		align-items: center;
		display: flex;
		margin-top: 0;
		width: 100%;

		.content-wrap {
			display: block;
			position: relative;
			width: 100%;
			z-index: 1;
		}

		&.image {
			height: 600px;

			.content-wrap {
				.text {
					h1,h4 {
						color: #fff;
					}
				}
			}
		}

		&.no-image {
			height: 430px;

			.content-wrap {
				.text {
					padding-bottom: 4rem;
					position: relative;
					text-align: center;

					.icon {
						background-repeat: no-repeat;
						background-size: 100% 100%;
						bottom: 0;
						display: block;
						height: 29px;
						left: 50%;
						position: absolute;
						transform: translateX(-50%);
						width: 248px;
					}
				}
			}
		}
	}

	&.intro-text-icon {
		.icon {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			height: 50px;
			margin-bottom: 1.5rem;
			vertical-align: middle;
			width: 50px;
		}

		h2 {
			margin-bottom: 2rem;
		}
	}

	&.imageblock {
		margin: 0;
		padding: 4rem 0;
		position: relative;

		.image {
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&.threequarters {
			.image {
				width: 75%;
			}
		}

		&.textright {
			.image {
				left: 0;
			}
		}

		&.textleft {
			.image {
				right: 0;
			}
		}

		.color-block {
			padding: 3rem;
			position: relative;
			z-index: 2;

			.text {
				color: #fff;

				h1, h2, h3, h4 {
					color: #fff;
					margin-bottom: 1.5rem;
				}

				ul li, ol li, p, a {
					color: #fff;
				}
			}

			&.blossom {
				background-color: rgba($color-blossom, 0.9);

				.text a:hover {
					color: $color-blossom;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}

			&.cherry {
				background-color: rgba($color-cherry, 0.9);

				.text a:hover {
					color: $color-cherry;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}

			&.harvest {
				background-color: rgba($color-harvest, 0.9);

				.text a:hover {
					color: $color-harvest;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}

			&.water-creation {
				background-color: rgba($color-water-creation, 0.9);

				.text a:hover {
					color: $color-water-creation;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}

			&.culture {
				background-color: rgba($color-culture, 0.9);

				.text a:hover {
					color: $color-culture;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}

			&.history {
				background-color: rgba($color-history, 0.9);

				.text a:hover {
					color: $color-history;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}

			&.text {
				background-color: rgba($color-bodytext, 0.9);

				.text a:hover {
					color: $color-bodytext;
				}
			}

			&.junction {
				background-color: rgba($color-junction, 0.9);

				.text a:hover {
					color: $color-junction;
				}

				h1, h2, h3, h4 {
					color: $color-bodytext;
				}

				ul li, ol li, p, a {
					color: $color-bodytext;
				}
			}
		}
	}

	&.title-row {
		.text {
			h2 {
				margin: 0;
				text-align: center;
			}

			&.has-icon {
				border-bottom: 1px solid $color-grey;
				padding: 0 5rem 2rem;
				position: relative;

				.icon {
					background-position: center center;
					background-repeat: no-repeat;
					background-size: contain;
					bottom: 2px;
					display: block;
					height: 50px;
					left: 0;
					position: absolute;
					width: 50px;
				}
			}
		}
	}

	&.title-row-icons {
		.text {
			h2 {
				margin: 0;
				text-align: center;
			}

			&.has-icon {
				border-bottom: 1px solid $color-grey;
				padding: 0 5rem 2rem;
				position: relative;

				.icon {
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					display: block;
					height: 80px;
					position: absolute;
					top: 0;
					width: 80px;
				}

				.icon1 {
					left: 0;
				}

				.icon2 {
					right: 0;
				}
			}
		}
	}

	&.grid {

		.column {
			padding: .3rem;
		}
	}

	&.grid-filter {
		background-color: $color-blossom;
		margin: 0;
		padding: 4rem 0;

		.routemaps,
		.filter-map {
			height: 600px;

			.grid-item {
				width: 260px;
			}
		}

		.grid-item {
			height: 225px;

			&:before {
				display: none;
			}
		}

		.posttype {
			background-color: #fff;
			margin-bottom: .5rem;
			padding: .3rem .5rem;

			span {
				color: $color-grey;
				font-size: rem-calc(13px);

				i {
					margin-right: .4rem;
				}
			}
		}

		.filter-row {
			display: block;
			margin-bottom: 3rem;
			position: relative;

			&.odd {
				margin-bottom: 7rem;
			}

			.title {
				color: $color-grey;
				left: 0;
				position: absolute;
				top: 0;
			}

			.search-section {
				display: inline-block;
				padding-top: 2.5rem;
				position: relative;
				vertical-align: middle;

				.search {
					display: block;
					margin: 0 1.5rem 0 0;
					position: relative;

					&:after {
						background-image: url(../img/search-icon.svg);
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 30px 30px;
						content: '';
						display: block;
						height: 100%;
						pointer-events: none;
						position: absolute;
						right: 0;
						top: 0;
						width: 50px;
					}
				}

				.filter-search {
					box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
					display: inline-block;
					height: 50px;
					margin: 0;
					vertical-align: middle;
					width: 300px;

					&::placeholder {
						color: $color-grey;
					}
				}

				.filter-submit {
					height: 100%;
					opacity: 0;
					position: absolute;
					right: 0;
					top: 0;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.grid-filters {
				display: inline-block;
				overflow: hidden;
				padding-top: 2.5rem;
				position: relative;
				vertical-align: middle;

				input[type=checkbox] {
					left: -9999px;
					opacity: 0;
					position: absolute;
				}

				select {
					box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
					color: $color-grey;
					display: inline-block;
					height: 50px;
					margin: 0;
					overflow: visible;
					transition: $transition;
					vertical-align: middle;
					width: 300px;

					&:hover,
					&:hover {
						border: 1px solid #8a8a8a;
						box-shadow: 0 0 5px #cacaca;
						cursor: pointer;
					}
				}
			}

			.change-view {
				position: absolute;
				right: 0;
				top: 45px;
				width: 150px;

				button {
					background-color: #fff;
					display: block;
					float: left;
					font-size: rem-calc(13px);
					padding: .8rem .5rem;
					transition: $transition;
					width: 50%;

					i {
						margin-right: .3rem;
					}

					&:hover,
					&.is-active {
						background-color: $color-green;
						color: #fff;
						cursor: pointer;
					}
				}
			}
		}
	}

	&.four-items-layout,
	&.five-items-layout {
		.image-wrap {
			display: block;
			padding: .3rem;

			.image {
				display: block;
				position: relative;
				transition: $transition;
				width: 100%;

				&:after {
					background: linear-gradient(to bottom,transparent,#000);
					bottom: 0;
					content: '';
					display: block;
					height: 50%;
					left: 0;
					opacity: 1;
					position: absolute;
					transition: $transition;
					width: 100%;
				}

				.title {
					bottom: 0;
					color: #fff;
					display: block;
					font-family: $header;
					left: 0;
					position: absolute;
					transition: $transition;
					width: 100%;
					z-index: 3;
				}

				&.size-large {
					height: 480px;

					.title {
						font-size: rem-calc(28px);
						line-height: rem-calc(38px);
						padding: 1.5rem;
					}
				}

				&.size-small {
					height: 225px;

					.title {
						font-size: rem-calc(20px);
						line-height: rem-calc(28px);
						padding: 1rem;
					}
				}

				&:hover {

					.title {
						bottom: 2rem;
					}
				}
			}
		}
	}

	&.five-items-layout {
		.image-wrap {
			.image {
				&.size-large {
					height: 481px;
				}

				&.size-small {
					height: 236px;
				}
			}
		}
	}
	&.text-background {
		margin: 0;
		padding: 4rem 0;
		position: relative;

		.image {
			background-position: left bottom;
			background-size: contain;
			bottom: 0;
			height: 100%;
			left: 0;
			opacity: .2;
			position: absolute;
			width: 40%;
		}

		&.bg-blossom {
			background-color: rgba($color-blossom, 0.9);

			.text a:hover {
				color: $color-blossom;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		&.bg-cherry {
			background-color: rgba($color-cherry, 0.9);

			.text a:hover {
				color: $color-cherry;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		&.bg-harvest {
			background-color: rgba($color-harvest, 0.9);

			.text a:hover {
				color: $color-harvest;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		&.bg-water-creation {
			background-color: rgba($color-water-creation, 0.9);

			.text a:hover {
				color: $color-water-creation;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		&.bg-culture {
			background-color: rgba($color-culture, 0.9);

			.text a:hover {
				color: $color-culture;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		&.bg-history {
			background-color: rgba($color-history, 0.9);

			.text a:hover {
				color: $color-history;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		&.bg-text {
			background-color: rgba($color-bodytext, 0.9);

			.text a:hover {
				color: $color-bodytext;
			}
		}

		&.bg-junction {
			background-color: rgba($color-junction, 0.9);

			.text a:hover {
				color: $color-junction;
			}

			h1, h2, h3, h4 {
				color: $color-bodytext;
			}

			ul li, ol li, p, a {
				color: $color-bodytext;
			}
		}

		.text {
			position: relative;
			z-index: 1;

			ul li,
			ol li,
			p {
				color: #fff;
			}

			h1,h2,h3,h4 {
				color: #fff;
				font-family: $header-alt;
				font-weight: 400;
				margin-bottom: 1.5rem;
			}

			.btn {
				margin-top: .5rem;
			}
		}
	}

	&.items-slider {
		background-color: $color-blossom;
		margin: 0;
		padding: 4rem 0;

		&.white {
			background-color: #FFF;
		}

		.title-row {
			margin: 0 0 2rem 0;
		}

		.btn {
			margin-top: 1.5rem;
		}
	}

	&.content-row {
		.intro-text {
			color: $color-blue;
			font-family: $header;
			font-size: rem-calc(28px);
			line-height: rem-calc(38px);
			margin: 1.5rem 0;
		}
	}

	&.logo-row {
		background-color: $color-grey-light;
		margin: 0;
		padding: 3rem 0;

		.text-wrap {
			align-items: center;
			display: flex;
			height: 100%;
			width: 100%;

			.text {
				display: block;
				width: 100%;

				h3 {
					margin: 0;
				}
			}
		}

		.logo-slider {

			.slick-arrow {
				background-color: #fff;
				border-radius: 50%;
				box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
				color: $color-grey;
				height: 50px;
				position: absolute;
				text-align: center;
				top: 50%;
				transition: $transition;
				width: 50px;
				z-index: 5;

				&.slick-prev {
					left: 0;
					transform: translate(-50%,-50%);
				}

				&.slick-next {
					right: 0;
					transform: translate(50%,-50%);
				}

				&:hover {
					background-color: $color-blue;
					color: #fff;
					cursor: pointer;
				}
			}

			.logo-item {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				display: block;
				filter: grayscale(100%);
				height: 75px;
				transition: $transition;
				width: 100%;

				&:hover {
					filter: grayscale(0%);
				}
			}
		}
	}

	&.map {

		h2 {
			margin-bottom: 3rem;
			text-align: center;
		}

		.poimap {
			min-height: 800px;
		}

		.gm-style-iw {
			// top: 23px!important;
			// left: 23px!important;
			// width: 300px!important;
			// height: 350px!important;

			.container {
				width: 300px;
			}
		}
	}

	&.favorites-tabs {
		.tabs {
			border: 0;
			display: flex;
			justify-content: space-between;

			&:before,
			&:after {
				display: none;
			}

			.tabs-title {
				display: inline-block;
				float: none;
				vertical-align: middle;

				a {
					color: $color-grey;
					font-size: 1.125rem;
					line-height: rem-calc(32px);
					padding: 1rem 0 1rem 2rem;
					position: relative;

					&:before {
						background-color: #fff;
						border: 2px solid #fff;
						border-radius: 50%;
						box-shadow: 0px 0px 0px 1px #979797;
						content: '';
						display: block;
						height: 20px;
						left: 0;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						transition: $transition;
						width: 20px;
					}

					&:focus,
					&:hover {
						background-color: transparent;

						&:before {
							background-color: $color-blue-light;
						}
					}
				}

				&.is-active {
					background-color: transparent;

					a {
						background-color: transparent;

						&:before {
							background-color: $color-blue;
						}
					}
				}
			}
		}

		.tabs-content {
			border: 0;

			.tabs-panel {
				padding: 0;

				.gform_wrapper {
					background-color: $color-grey-light;

					.gform_body {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	&.arrangements {
		.item {
			position: relative;

			.image-container {
				background-size: cover;
				padding: 0 0 60%;
				position: relative;

				.price-container {
					background-color: $color-green;
					border-radius: 50%;
					position: absolute;
					right: -1.5rem;
					top: -1.5rem;

					.discount-wrapper {
						position: absolute;

						.discount-bg {
							background-color: #FFF;
							border-radius: 50%;
							bottom: 30px;
							height: 60px;
							left: -20px;
							position: relative;
							width: 60px;

							.discount {
								color: $color-green;
								font-weight: 700;
								position: absolute;
								text-align: center;
								top: 50%;
								transform: translateY(-50%);
								width: 100%;
							}
						}
					}

					.background {
						height: 120px;
						position: relative;
						width: 120px;

						.wrapper {
							position: absolute;
							text-align: center;
							top: 50%;
							transform: translateY(-50%);
							width: 100%;

							.prefix {
								color: #FFF;
								display: block;
								font-size: .8rem;
								font-style: italic;
							}

							.price {
								color: #FFF;
								display: block;
								font-size: 1.7rem;
								font-weight: 700;
								line-height: 1;

								&.pricetext {
									font-size: .9rem;
									padding: 0 rem-calc(5px);
								}
							}
						}
					}
				}
			}

			.item-content {
				background-color: rgba( $color-green, .05);
				border: 1px solid rgba( $color-green, .2);
				border-top: none;
				padding: 1.5rem 1.5rem 5rem 1.5rem;

				.title {
					color: $color-green;
					font-family: $header;
					font-size: 1.4rem;
					font-weight: 700;
				}

				.date {
					color: $color-grey;
					font-size: rem-calc(14px);
					font-weight: 700;
					margin-bottom: .5rem;
				}

				.item-intro {
					line-height: rem-calc(25px);

					ul,
					ol {
						list-style-type: none;

						li {
							margin: 0 0 0 .5rem;
							position: relative;

							&:before {
								background-image: url(../img/li-shape.svg);
								color: $color-green;
								content: '\f00c';
								display: inline-block;
								font-family: $icons;
								height: 16px;
								margin: 0 .75rem 0 -1.75rem;
								position: absolute;
								width: 20px;
							}
						}
					}
				}

				.read-more {
					bottom: 1.5rem;
					position: absolute;
				}
			}
		}
	}


	&.columnsblock {

		.text {
			display: block;
			height: 100%;
			padding-bottom: 4.5rem;
			position: relative;

			.btn {
				bottom: 0;
				left: 0;
				margin: 0;
				position: absolute;

				@media screen and (max-width: 40rem) {
					margin-bottom: 2rem;
				}
			}
		}

		h2 {
			font-size: 1.5rem;
		}

		.image {
			position: relative;

			&:before {
				content: '';
				display: block;
				padding-top: 50%;
			}
		}
	}

	&.anchorlink-block {
		.image-container {
			.image {
				background-position: center;
				background-size: cover;
				padding: 0 0 80%;
			}
		}

		.inner {
			padding: 0 0 0 2rem;

			.content {
				p, h2 {
					color: $color-grey;
				}

				ul,
				ol {
					li {
						a {
							color: $color-grey;
						}
					}
				}
			}
		}
	}
}


.section.map,
.grid-filter,
.routemaps {
	.gm-style-iw {

		.container {
			// width: 300px;

			.image {
				display: block;
				height: 100px;
				margin-bottom: .5rem;
				width: 100%;
			}

			.title {
				font-size: 1.125rem;
				font-weight: 700;
				margin-bottom: .5rem;
			}

			.meta {
				margin-bottom: .5rem;
			}

			.posttype {
				height: 25px;
				margin: .5rem 0 0 0;
				padding: 0;
			}

			a {
				color: $color-grey;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.slider-items {
	.grid-item {
		height: 225px;

		&:before {
			display: none;
		}
	}

	.column {
		padding: .3rem;
	}

	.slick-arrow {
		background-color: #fff;
		border-radius: 50%;
		box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
		color: $color-grey;
		height: 50px;
		position: absolute;
		text-align: center;
		top: 50%;
		transition: $transition;
		width: 50px;
		z-index: 5;

		&.slick-prev {
			left: 0;
			transform: translate(-50%,-50%);
		}

		&.slick-next {
			right: 0;
			transform: translate(50%,-50%);
		}

		&:hover {
			background-color: $color-blue;
			color: #fff;
			cursor: pointer;
		}
	}
}


.grid-item {
	background-blend-mode: multiply;
	background-color: transparent;
	display: block;
	position: relative;
	transition: $transition;
	width: 100%;

	&:before {
		content: '';
		display:  block;
		padding-top: 100%;
	}

	&:after {
		background: linear-gradient(to bottom,transparent,#000);
		bottom: 0;
		content: '';
		display: block;
		height: 50%;
		left: 0;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		transition: $transition;
		width: 100%;
		z-index: 1;
	}

	.grid-content {
		bottom: 0;
		height: 62px;
		left: 0;
		overflow: hidden;
		padding: 1rem;
		position: absolute;
		transition: all .5s ease;
		width: 100%;
		z-index: 2;

		.title {
			color: #fff;
			display: block;
			font-family: $header;
			font-size: rem-calc(20px);
			margin-bottom: 1rem;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: $transition;
			white-space: nowrap;
		}

		.meta {
			color: #fff;
			display: block;
			font-size: rem-calc(13px);
			opacity: 0;
			transition: $transition;

			.tag {
				background-color: $color-blue;
				color: #fff;
				display: inline-block;
				margin: .1rem 0;
				padding: .4rem;
				vertical-align: middle;
			}
		}
	}

	&:hover {
		background-color: $color-grey;

		&:after {
			opacity: 0;
		}

		.grid-content {
			height: 100%;

			.title {
				white-space: normal;
			}

			.meta {
				opacity: 1;
			}
		}
	}
}

.posttype {
	align-items: center;
	background-color: #fff;
	display: flex;
	height: 30px;
	margin-bottom: .5rem;
	padding: .3rem .5rem;
	position: relative;

	span {
		color: $color-grey;
		display: block;
		font-size: rem-calc(13px);
		position: absolute;
		transition: $transition;

		i {
			margin-right: .4rem;
		}
	}
}

.intro-text-style {
	font-family: $header;
	font-size: rem-calc(20px);
	line-height: rem-calc(28px);
	margin: 2rem 0 0 0;
	text-align: center;
}

.gform_wrapper .gform_body .gform_fields .gfield .ginput_container_fileupload input {
	width: 100%;
}

.gform_wrapper .gform_body .gform_fields .gfield .ginput_container_textarea textarea {
	height: 200px;
}

.single-template {
	padding-top: 75px;

	.fixed-sidebar {
		padding-right: 3rem;
	}

	.not-payed {

		.sidebar-section.photo-gallery {

			.photo-no-pay {
				height: 100%;
			}
		}
	}

	.sidebar-section {
		display: block;
		height: calc(50vh - 38px);
		margin-bottom: 0;
		overflow: hidden;

		.slick-list {
			height: 100%;

			.slick-track {
				height: 100%;

				.slick-slide {
					height: 100%;

					.image {
						height: 100%;

						&:before {
							display: none;
						}
					}
				}
			}
		}

		&.photo-gallery {
			.gallery-slider {
				height: 100%;

				.image {
					background-position: center center;
					height: 100%;

					&:before {
						content: '';
						display: block;
						padding-top: 100%;
					}
				}

				.slick-arrow {
					background-color: rgba(#fff,.8);
					border-radius: 50%;
					box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
					color: $color-grey;
					height: 50px;
					position: absolute;
					text-align: center;
					top: 50%;
					transform: translateY(-50%);
					transition: $transition;
					width: 50px;
					z-index: 5;

					&.slick-prev {
						left: 1.25rem;
					}

					&.slick-next {
						right: 1.25rem;
					}

					&:hover {
						background-color: rgba($color-blue,.8);
						color: #fff;
						cursor: pointer;
					}
				}
			}

			.photo-no-pay {
				height: 100%;
				overflow: hidden;
				position: relative;

				.image {
					filter: blur(4px);
					height: 105%;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%,-50%);
					width: 105%;

					&:before {
						display: none;
					}
				}
			}
		}

		&.map-single-marker {
			.acf-map-single {
				display: block;
				height: 100%;
				width: 100%;
			}
		}
	}

	.breadcrumbs {
		color: $color-grey;
		font-size: .9rem;
		margin: 2rem 0 2rem;

		a {
			color: $color-grey;
			font-size: .9rem;
			text-decoration: underline;
		}

		i {
			font-size: .4rem;
			padding: 0 .3rem;
		}
	}

	.call-to-action {
		color: $color-grey;
		font-size: .9rem;
		margin: 2rem 0;

		a {
			color: $color-grey;
			padding: 0 1rem 0 0;
			transition: $transition;

			i {
				margin-right: .3rem;
			}

			&.fav {
				i {
					color: red;
				}
			}

			&:hover {
				text-decoration: underline;
			}
		}

		.socialshare {
			display: inline-block;
			margin: 0 0 0 1rem;
		}

		.has-tip {
			border-bottom: 0;
		}
	}

	.route-data,
	.event-data {
		background: $color-grey-light;
		font-size: 1.125rem;
		font-weight: 600;
		line-height: 55px;
		margin: 0 0 2rem;
		overflow: hidden;
		padding: .4rem;

		.durationkm {
			color: $color-blue;
			margin: 0 .5rem 0 0;
		}

		.durationebike {
			font-weight: 400;
		}

		img {
			float: left;
			margin: 0 1rem 0 0;
			width: 55px;
		}
	}

	.meta-info {
		margin: 0 0 2rem;

		.heading-light {
			padding: 2rem 0 0;
		}

		.contact-us {
			a {
				color: $color-grey;
				display: block;
				font-size: 1.125rem;
				margin: 0 0 .5rem;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}

			.fab,
			.fal {
				background: $color-blue;
				border-radius: 50%;
				color: #fff;
				height: 25px;
				line-height: 25px;
				margin: 0 1rem 0 0;
				text-align: center;
				width: 25px;
			}

			.socials {
				a {
					float: left;
				}
			}
		}

		.contact-info {
			margin: 0 0 2rem;

			p {
				margin-bottom: 0;
			}
		}
	}

	.routemaps {
		height: 100%;
	}


	.text .usps:not(.gform_fields) {
		list-style: none;

		li {
			padding: 0 0 0 2.5rem;
			position: relative;

			&:before {
				background-color: transparent;
				background-image: url(../img/usp.svg);
				background-repeat: no-repeat;
				content: '';
				display: block;
				height: 35px;
				top: 0;
				width: 25px;
			}
		}
	}

	.accessibility {
		margin: 0 0 2rem;

		img {
			border: 0;
			height: 50px;
			margin: 0 .5rem 0 0;
			width: 50px;
		}
	}

	.route {
		overflow: hidden;

		.bar {
			background: $color-grey-light;
			float: left;
			margin: 0 0 2px;
			overflow: hidden;
			padding: 1rem;
			width: 100%;

			strong {
				display: block;
			}

			&:nth-child(1),
			&:nth-child(3) {
				float: left;

				img {
					float: left;
					margin: .5rem 1rem 0 0;
				}

				div {
					float: left;
				}
			}

			&:nth-child(2) {

				.image {
					background-position: center;
					background-repeat: no-repeat;
					display: inline-block;
					height: 40px;
					margin: 1rem .5rem 0 0;
					position: relative;
					width: 40px;
				}

				.number {
					height: 40px;
					line-height: 40px;
					position: absolute;
					text-align: center;
					width: 40px;
				}

				img {
					margin: -2rem .5rem 0 0;
				}
			}


			img {
				width: 30px;
			}
		}

		.presentedby {
			clear: both;
			float: left;
			margin: 2rem 0 0;
		}
	}

	.body-text {
		height: 200px;
		margin: 0 0 2rem;
		overflow: hidden;
		position: relative;

		&.show {
			height: auto;
			overflow: visible;
		}

		.fade {
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
			bottom: 0;
			cursor: pointer;
			font-weight: 600;
			height: 45px;
			left: 0;
			position: absolute;
			text-align: center;
			width: 100%;
		}
	}


	.info {
		padding: 3rem 0;
	}

	h2 {
		border-bottom: 1px solid $color-grey;
	}

}

.search-results {
	.header {
		margin-bottom: 0;
	}
}

.pagination {
	margin: 3rem 0 0 0;

	li {
		display: inline-block;
		margin: .2rem;

		span,
		a {
			background-color: #fff;
			border: 1px solid #979797;
			border-radius: 50%;
			color: $color-grey;
			display: inline-block;
			font-family: $header-alt;
			font-size: rem-calc(22px);
			height: 45px;
			line-height: rem-calc(37px);
			padding: 0;
			text-align: center;
			text-decoration: none;
			transition: $transition;
			vertical-align: middle;
			width: 45px;

			&:hover,
			&.current {
				background-color: $color-history;
				border-color: $color-history;
				color: #fff;
				padding: 0;
			}
		}
	}
}

.slick-list {
	padding: 0 !important;
}



.section.route {
	h2 {
		margin: 0 0 1rem;
	}

	.routemaps {
		height: 400px;
	}

	.beginend {
		margin: 1rem 0;
	}

	.bar {
		.image {
			background-position: center;
			background-repeat: no-repeat;
			display: inline-block;
			height: 40px;
			margin: .3rem .3rem 0 0;
			position: relative;
			width: 40px;
		}

		.number {
			height: 40px;
			line-height: 40px;
			position: absolute;
			text-align: center;
			width: 40px;
		}

		img {
			margin: -2rem .5rem 0 0;
		}
	}
}

.leaflet-marker-icon {
	border-color: #a4bf57;
	border-radius: 50%;
	border-width: 4px;
	font-size: 1rem;
	height: 35px!important;
	line-height: 30px;
	text-align: center;
	width: 35px!important;
}

.leaflet-shadow-pane,
img[src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png"],
img[src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png"]	{
	display: none;
}


.buttercookies {
	font-family: 'Butter cookies', sans-serif;
	font-size: 200%;
}

.smaller {
	display: block;
	font-size: 2.5rem;
}


.list-item {
	margin-top: 1.875rem;

	.content-container {
		flex: 1;

		* {
			transition: 0.3s;
		}
	}

	.subtitle {
		font-size: rem-calc(20);
		font-weight: 700;
		line-height: rem-calc(28);
		margin-bottom: rem-calc(8);
	}

	.link-title {
		display: block;
		font-size: rem-calc(14);
		line-height: 1;
		margin-top: rem-calc(8);
		padding: 1rem;
		width: fit-content;
	}

	&.has-link {
		&:hover {
			opacity: 0.8;

			.image {
				transform: scale(1.05);
			}
		}
	}

	a {
		&.link-full {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 99;
		}
	}

	.image-container {
		margin-right: 1rem;
		overflow: hidden;
		width: 250px;
	}

	.image {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		transition: 0.3s;

		&::after {
			content: "";
			display: block;
			padding-bottom: 60%;
		}

		> img {
			display: none;
		}
	}
}

.list-block {
	&:first-child {
		margin-top: 3rem;

		@include tablet {
			margin-top: 8rem;
		}
	}

	.titlewrap {
		margin-bottom: 3rem;
	}

	.list-item {
		.inner {
			position: relative;

			.imagewrap {
				height: 100%;
				position: relative;
				width: 100%;

				.image {
					background-position: center;
					background-size: cover;
					position: relative;
					width: 100%;

					&:after {
						content: '';
						display: block;
						padding-bottom: 75%;
					}

					.count {
						align-items: center;
						background: $color-cherry;
						border: 1px solid rgba( $color-cherry, 0.5 );
						border-radius: 50%;
						color: #fff;
						display: flex;
						font-family: 'Butter cookies', sans-serif;
						font-size: rem-calc(48);
						font-weight: 900;
						height: 50px;
						justify-content: center;
						left: -10px;
						position: absolute;
						top: -10px;
						width: 50px;
						z-index: 2;

						@include tablet {
							left: -25px;
							top: -25px;
						}
					}

					> img {
						display: none;
					}

					@include tablet {
						&:after {
							padding-bottom: 62%;
						}
					}
				}
			}

			.content-container {
				padding: 1rem 0;

				h4 {
					font-size: 1.25rem;
					line-height: 1.375rem;
				}

				.subtitle {
					font-size: 1rem;
					font-weight: 600;
				}

				.content {
					font-size: 1rem;
					margin: .875rem 0 0;
				}

				.link-title {
					line-height: 1rem;
					margin-top: .875rem;
					padding: .5rem 1rem;
				}
			}
		}

		&:hover {
			.content-container {
				.link-title {
					background-color: $color-history;
					color: white;
				}
			}
		}
	}
}

.poiitem {
	.inner {
		position: relative;

		.grid-item {
			.infometa {
				align-items: center;
				display: flex;
				position: absolute;
				right: .75rem;
				top: .75rem;

				.infoicon {
					align-items: center;
					background-color: $color-harvest;
					border-radius: 6px 0 6px 0;
					color: white;
					display: flex;
					font-size: 1.375rem;
					height: 36px;
					justify-content: center;
					transition: all .2s linear;
					width: 36px;

					&.calendar {
						background-color: $color-history;
						margin-right: .75rem;
					}
				}
			}

			.grid-content {
				.meta {
					> a {
						color: white;
						display: block;
						font-weight: bold;
						text-decoration: underline;

						&:hover {
							text-decoration: none;
						}
					}

					.addressmeta {
						span,
						a {
							color: white;
							display: block;
							font-size: rem-calc(13px);
							line-height: 1.5rem;
						}

						a {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.posttype {
			align-items: center;
			background-color: #fff;
			display: flex;
			height: 30px;
			margin-bottom: .5rem;
			padding: .3rem .5rem;
			position: relative;

			.more-info {
				color: $color-grey;
				color: $color-history;
				display: block;
				font-size: rem-calc(13px);
				margin: 0 0 0 auto;

				a {
					color: $color-history;
				}

				i {
					margin-left: 3px;
				}
			}
		}

		.link {
			cursor: pointer;
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}

		&:hover {
			.grid-item {
				background-color: #666;

				.infoicon {
					opacity: 0;
					pointer-events: none;
				}

				&:after {
					opacity: 0;
				}

				.grid-content {
					height: 100%;

					.title {
						white-space: normal;
					}

					.meta {
						opacity: 1;
					}
				}
			}
		}
	}
}

.three-items-block {
	.image-wrap {
		display: block;
		padding: .3rem;

		.image {
			display: block;
			position: relative;
			transition: $transition;
			width: 100%;

			&:after {
				background: linear-gradient(to bottom,transparent,#000);
				bottom: 0;
				content: '';
				display: block;
				height: 50%;
				left: 0;
				opacity: 1;
				position: absolute;
				transition: $transition;
				width: 100%;
			}

			.title {
				bottom: 0;
				color: #fff;
				display: block;
				font-family: $header;
				left: 0;
				position: absolute;
				transition: $transition;
				width: 100%;
				z-index: 3;
			}

			&.size-large {
				height: 481px;

				.title {
					font-size: rem-calc(28px);
					line-height: rem-calc(38px);
					padding: 1.5rem;
				}
			}

			&.size-small {
				height: 236px;

				.title {
					font-size: rem-calc(20px);
					line-height: rem-calc(28px);
					padding: 1rem;
				}
			}

			&:hover {

				.title {
					bottom: 2rem;
				}
			}
		}
	}
}

.post-overview-list-block {
	.nopadding {
		padding-left: 0;
		padding-right: 0;
	}
	.list-block {
		.list-item {
			.inner {
				.imagewrap {
					.image {
						&::after {
							padding-bottom: 100%;
						}
					 }
				}
			}
		}
	}
}

#ui-datepicker-div {
	max-width: 320px;

	@include tablet {
		max-width: auto;
	}
}
